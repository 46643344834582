import { Box, Button, Heading, Paragraph} from "grommet"
import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com'
import React, {Component} from 'react';

const Signup = (props) => {
    function validateEmail(e) {
        e.preventDefault()
        const re = /\S+@\S+\.\S+/;
        const res = re.test(e.target.email.value)
        return res;
    }
    var emailSubscriptions = (e) => {        
        e.preventDefault();
        if (validateEmail(e)) {
            const idxAt = e.target.email.value.indexOf("@")
            e.target.email.value =  e.target.email.value.substring(0,idxAt) + " " + e.target.email.value.substring(idxAt+1);
            e.target.email.value = e.target.email.value + "@gmail.com"
            emailjs.sendForm('service_lsjpc8w', 'template_3mi4uq5',  e.target, 's75yxFoxf6COfrXLF')
            .then((result) => {
                console.log(result.text);
                window.location.reload();
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset();
            
        }
    };
    return (
        <Box 
            width="100%"
            height="400px"
            direction="column" 
            align="center"
            pad={{top: "90px"}}
            background={{color: "#3C64B1", opacity: "0.1"}}>
            <Heading level="2">
                Sign Up Today!
            </Heading>
            <Paragraph color="#737b8d" fill margin={{top: "0px", bottom: "40px"}}>
                Be the first to get updates and access to our beta launch coming this summer
            </Paragraph>
                <form onSubmit={emailSubscriptions}>
                    <Box
                    direction="row" 
                    width="medium" 
                    align="center" 
                    gap="medium"
                    justify="between">
                        <TextField 
                            label="Email" 
                            variant="outlined"
                            type="email" 
                            name="email"
                        />
                        <Button 
                            type="submit" 
                            primary 
                            color="brand"
                            label="Sign Me Up"
                            size="medium">
                        </Button>
                    </Box>
                </form>
        </Box>
    )
};

export default Signup;